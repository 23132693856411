import { firebaseService } from "@/services/FireAuthService";
import { DocumentData, DocumentSnapshot } from "firebase/firestore";

export abstract class BaseModel<T extends BaseModel<T>> {
  public docSnap: DocumentSnapshot | null = null;

  protected abstract toDocData(): DocumentData;
  protected abstract getDocPath(): string;
  public abstract getDocKey(): string;

  public async getDoc(): Promise<DocumentSnapshot> {
    return await firebaseService.fireStore.getDoc(
      this.getDocPath(),
      this.getDocKey()
    );
  }
  public async update(): Promise<void> {
    await firebaseService.fireStore.setDoc(
      this.getDocPath(),
      this.getDocKey(),
      this.toDocData()
    );
  }
  public async delete(): Promise<boolean> {
    return await firebaseService.fireStore.deleteDoc(
      this.getDocPath(),
      this.getDocKey()
    );
  }
}
