import HistoryModel from "@/models/HistoryModel";
import {
  initializeFirestore,
  persistentLocalCache,
  collection,
  query,
  CACHE_SIZE_UNLIMITED,
  Firestore,
  getDocsFromCache,
  getDocsFromServer,
  setDoc,
  getDoc,
  doc,
  limit,
  persistentMultipleTabManager,
  deleteDoc,
  QuerySnapshot,
  DocumentData,
  QueryConstraint,
} from "firebase/firestore";
import { FirebaseApp } from "firebase/app";
import SessionService, { CacheType } from "@/services/SessionService";
import UserService from "./UserService";

// オフライン永続性を有効にする

export default class FireStoreService {
  private firestore: Firestore;

  // ユーザーの履歴データを取得する関数
  constructor(app: FirebaseApp) {
    this.firestore = initializeFirestore(app, {
      localCache: persistentLocalCache({
        cacheSizeBytes: CACHE_SIZE_UNLIMITED,
        tabManager: persistentMultipleTabManager(),
      }),
    });
  }

  public async getDoc(docPath: string, docId: string) {
    const docRef = doc(this.firestore, docPath, docId);
    return await getDoc(docRef);
  }
  public async setDoc(docPath: string, docId: string, docData: DocumentData) {
    const docRef = doc(this.firestore, docPath, docId);
    setDoc(docRef, docData);
  }

  public async deleteDoc(docPath: string, docId: string): Promise<boolean> {
    const docRef = doc(this.firestore, docPath, docId);
    // 成功したらtrueを返す
    try {
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error("履歴の削除に失敗しました:", error);
      return false;
    }
  }

  public async getDocumentSnapshots(
    docPath: string,
    limitAmount: number,
    nowModelsLength: number,
    ...conditions: QueryConstraint[]
  ): Promise<QuerySnapshot<DocumentData, DocumentData>> {
    const historyRef = collection(this.firestore, docPath);
    const q = query(historyRef, limit(limitAmount), ...conditions);

    //watchedPagesにpageが存在しない場合は、pageを追加してデータを取得する
    let querySnapshot;
    if (UserService.IsCachedItem(CacheType.History, nowModelsLength)) {
      querySnapshot = await getDocsFromCache(q);
    } else {
      querySnapshot = await getDocsFromServer(q);
      UserService.SetCachedMaxLength(
        CacheType.History,
        nowModelsLength + querySnapshot.docs.length
      );
    }
    let onlineReadCount = 0;
    let offlineReadCount = 0;
    querySnapshot.forEach((doc) => {
      doc.metadata.fromCache ? offlineReadCount++ : onlineReadCount++;
    });

    console.log(
      "onlineReadCount",
      onlineReadCount,
      "offlineReadCount",
      offlineReadCount
    );

    return querySnapshot;
  }

  // public async getAllDocumentSnapshots(
  //   docPath: string
  // ): Promise<QuerySnapshot<DocumentData, DocumentData>> {
  //   const historyRef = collection(this.firestore, docPath);
  //   const q = query(historyRef);

  //   //watchedPagesにpageが存在しない場合は、pageを追加してデータを取得する
  //   const querySnapshot = await getDocsFromServer(q);

  //   return querySnapshot;
  // }
  // public async setAllDocumentSnapshots(
  //   docPath: string,
  //   docs: QuerySnapshot<DocumentData, DocumentData>
  // ): Promise<void> {
  //   docs.forEach((doc) => {
  //     this.setDoc(docPath, doc.id, doc.data());
  //     console.log("setAllDocumentSnapshots", doc.id, doc.data());
  //   });
  // }
}
