<template>
  <div class="popup-overlay">
    <div class="popup">
      <span v-html="props.message"></span>
      <div>
        <button class="cancel" @click="cancelled">{{ props.cancelTxt }} </button>
        <button class="confirm" @click="confirmed">{{ props.confirmTxt }}</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps } from "vue";


const props = defineProps({
  message: {
    type: String,
    default: '',
  },
  cancelTxt: {
    type: String,
    default: 'Cancel',
  },
  confirmTxt: {
    type: String,
    default: 'Confirm',
  },
});
const emit = defineEmits(["confirmed", "cancelled"])

function confirmed() {
  // 処理の確認
  console.log('confirm')
  emit('confirmed')

}
function cancelled() {
  console.log('cancel')
  emit('cancelled')
}

</script>

<style scoped>
.popup-overlay {
  position: fixed;
  /* ページ全体に固定 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明の背景 */
  display: flex;
  justify-content: center;
  /* 中央寄せ */
  align-items: center;
  /* 中央寄せ */
  z-index: 1000;
  /* 他の要素より上に表示 */
}

.popup {
  background: white;
  /* padding: 20px; */
  width: 90%;
  max-width: 400px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;

  span {
    font-size: 20px;
    padding: 20px;
  }

  button {
    border-top: 1px solid #E5EDE6;

    width: 50%;
    height: 48px;
    color: black;
    /* テキスト色 */
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;

    &.cancel {
      border-right: 1px solid #E5EDE6;
      color: #007AFF;
    }

    &.confirm {
      border-left: 1px solid #E5EDE6;
      color: #FF3B30;
    }
  }
}

.popup button:hover {
  opacity: 0.8;
  /* ホバー時の透明度 */
}
</style>
