<!-- eslint-disable no-unused-vars -->
<template>
  <div class="relative text-center px-[16px]">
    <input type="text" v-model="inputText" @input="fetchPredictions" :placeholder="$t('lt_common.search_placeholder')"
      autocomplete="off" pattern="^[a-zA-Z]+$"
      class="w-full  h-[56px] pl-12 border-2  rounded-md focus:ring focus:ring-green-300 focus:border-green-300" />
    <img src="../assets/images/parts/icon_search_small.png" alt="Search"
      class="absolute top-[16px] left-[32px] transform w-6 h-6" />
    <button v-if="inputText.length >= 1">
      <img src="../assets/images/parts/button_search_delete.png" alt="Search"
        class="absolute top-[16px] right-[32px] transform  w-6 h-6" @click="deleteWord" />
    </button>
    <ul v-show="inputText" class="mx-auto relative w-full  hover:bg-green-50 cursor-pointer">

      <PredictionItem :word="prediction.word" v-for="prediction in predictions" :key="prediction.word">
        {{ prediction.word }}
      </PredictionItem>

      <li v-if="predictions.length === 0 && inputText.length < 2" class="p-2">
        2文字以上入力してください
      </li>
    </ul>
  </div>
  <section class="gl_ly_main_blank mt-[256px] my-[640px]" v-if="predictions.length == 0 && inputText.length >= 2">
    <img class="gl_bl_icon" src="../assets/images/parts/icon_search_large.png" alt="history..." loading="lazy"
      width="120px" height="120px">
    <span class="gl_bl_text"> {{ $t('main_blank.search') }}</span>
  </section>
</template>

<script setup lang="ts">
// eslint-disable-next-line no-unused-vars
import { ref, watch } from "vue";
import PredictionItem from "../components/PredictionItem.vue";


const inputText = ref("");
const predictions = ref([]);


watch(inputText, (newValue, oldValue) => {
  // Check if the new value contains non-alphabet characters
  if (!/^[a-zA-Z]*$/.test(newValue)) {
    // If it does, revert the value to the old one
    inputText.value = oldValue;
  }
});

const fetchPredictions = () => {
  if (inputText.value.length > 1) {
    fetch(
      `https://api.datamuse.com/sug?s=${encodeURIComponent(inputText.value)}`
    )
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data
          .filter((item) => !item.word.includes(" "))
          .slice(0, 10);
        predictions.value = filteredData;
      })
      .catch((error) => console.error("Error:", error));
  } else {
    predictions.value = []; // 入力が少ない場合はリストをクリア
  }
};

function deleteWord() {
  console.log("deleteWord");
  inputText.value = "";
  predictions.value = [];
}

</script>

<style scoped></style>
