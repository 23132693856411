import { reactive } from "vue";

import eventService, { SessionAction } from "./EventService"; // Import the SessionAction enum

// ストアの状態の型を定義
interface SessionState {
  isEditMode: boolean;
}

class RootStore {
  public sessionState: SessionState;
  // public userState: UserState;

  public ChangeEditMode(isEditMode: boolean): void {
    this.sessionState.isEditMode = isEditMode;
    eventService.emit(SessionAction.ChangeEditMode, isEditMode);
  }

  constructor() {
    this.sessionState = reactive<SessionState>({
      isEditMode: false,
    });
    // this.userState = reactive<UserState>({
    //   uid: UserService.getUserId(),
    //   tokenId: "",
    // });
  }
}

// シングルトンパターンでストアのインスタンスを作成
export const rootStore = new RootStore();
