import LocalStorageFacade from "./LocalStorageFacade";
import { v4 as uuidv4 } from "uuid";
import ServerService from "./ServerService";
import i18n from "@/main";
import { ServiceProvider } from "@/utils/ServiceProvider";
import { BookmarkService } from "./BookmarkService";
import { HistoryService } from "./HistoryService";

// import i18n from "../i18n";

export enum CacheType {
  History,
  // ChangeEditMode,
  // Delete,
}

export default class UserService {
  private static readonly USER_ID: string = "userId_v4";
  private static readonly USER_Pass: string = "userPass_v4";
  private static readonly USER_AUTH_METHOD: string = "userAuthMethod_v4";
  private static readonly USER_CACHED_MAX_LENGTH: string =
    "userCachedMaxLength_v4";
  private static readonly USER_LANGUAGE: string = "USER_LANGUAGE";
  private static readonly USER_LANGUAGE_EN: string = "en";

  private static readonly BROWSER_ID: string = "browserId_v4";

  public static async fetchUserInfo() {
    try {
      let userId = LocalStorageFacade.getItem(UserService.USER_ID);
      if (!userId) {
        // throw new Error("ユーザーIDがローカルストレージに存在しません。");
        userId = await UserService.Register();
      }

      const userDoc = await ServerService.getUserInfo(
        userId,
        UserService.getBrowserId()
      );
      if (!userDoc) {
        throw new Error("ユーザードキュメントの取得に失敗しました。");
      }

      const currentBrowserId = UserService.getBrowserId();
      if (userDoc.lastLoginBrowserId !== currentBrowserId) {
        console.log(
          "異なるブラウザからのアクセスが検出されました。キャッシュをリフレッシュします。"
        );
        UserService.ClearCachedMaxLength(CacheType.History); // キャッシュをクリア
      }

      // 言語設定を更新
      if (userDoc.languageSetting) {
        UserService.setLocalLanguageSetting(userDoc.languageSetting);
      }
    } catch (error) {
      console.error("ユーザー情報の取得中にエラーが発生しました:", error);
    }
  }

  public static setBrowserId(): void {
    if (!LocalStorageFacade.getItem(UserService.BROWSER_ID)) {
      const newBrowserId = uuidv4();
      LocalStorageFacade.setItem(UserService.BROWSER_ID, newBrowserId);
    }
  }

  public static getBrowserId(): string {
    let browserId = LocalStorageFacade.getItem(UserService.BROWSER_ID);
    if (!browserId) {
      UserService.setBrowserId();
      browserId = LocalStorageFacade.getItem(UserService.BROWSER_ID) as string;
    }
    return browserId;
  }

  public static async setLanguageSetting(
    languageSetting: string
  ): Promise<boolean> {
    const isSuccess = await ServerService.sendUserSetting(languageSetting);
    if (isSuccess) {
      UserService.setLocalLanguageSetting(languageSetting);
      return true;
    }
    return false;
  }
  public static setLocalLanguageSetting(languageSetting: string): void {
    if (UserService.getLanguageSetting() === languageSetting) {
      return;
    }
    LocalStorageFacade.setItem(UserService.USER_LANGUAGE, languageSetting);
    i18n.global.locale.value = languageSetting; // Vue 3のComposition APIを使用している場合
  }

  public static getLanguageSetting(): string {
    const languageSetting: string | null = LocalStorageFacade.getItem(
      UserService.USER_LANGUAGE
    );
    if (languageSetting != null) {
      return languageSetting;
    }
    return UserService.USER_LANGUAGE_EN;
  }

  public static isRegistered(): boolean {
    const userId: string | null = LocalStorageFacade.getItem(
      UserService.USER_ID
    );
    if (userId != null) {
      return true;
    }
    return false;
  }
  public static async Register(): Promise<string> {
    const userId = uuidv4();
    console.log("Register:" + userId);
    const userPass = await ServerService.registerUser(userId);
    if (userPass == null) {
      return "registered failed";
    }
    LocalStorageFacade.setItem(UserService.USER_ID, userId);
    LocalStorageFacade.setItem(UserService.USER_Pass, userPass);
    return userId;
  }

  public static LoginWithGoogle(userId: string): void {
    LocalStorageFacade.setItem(UserService.USER_ID, userId);
    LocalStorageFacade.setItem(UserService.USER_AUTH_METHOD, "google");
  }

  public static async SignInWithGoogle(
    beforeUserId: string,
    afterUserId: string
  ): Promise<boolean> {
    LocalStorageFacade.setItem(UserService.USER_AUTH_METHOD, "google");
    const isSuccess: boolean = await ServerService.signInUserWithGoogle(
      beforeUserId,
      afterUserId
    );
    if (isSuccess) {
      UserService.ClearCachedMaxLength(CacheType.History);
      LocalStorageFacade.setItem(UserService.USER_Pass, "");
      ServiceProvider.getInstance(BookmarkService).init();
      ServiceProvider.getInstance(HistoryService).init();
      return true;
    }
    return false;
  }
  public static IsAuthByGoogle(): boolean {
    const authMethod: string | null = LocalStorageFacade.getItem(
      UserService.USER_AUTH_METHOD
    );
    if (authMethod === "google") {
      return true;
    }
    return false;
  }

  public static getUserId(): string {
    return LocalStorageFacade.getItem(UserService.USER_ID) || "noRegistered";
  }
  public static getUserPass(): string {
    return LocalStorageFacade.getItem(UserService.USER_Pass) || "noRegistered";
  }

  public static IsCachedItem(cacheType: CacheType, maxLength: number): boolean {
    const cachedMaxLength: number = LocalStorageFacade.getInt(
      UserService.USER_CACHED_MAX_LENGTH + cacheType,
      0
    );
    console.log("cachedMaxLength", cachedMaxLength, "maxLength", maxLength);
    if (cachedMaxLength != null && cachedMaxLength > maxLength) {
      return true;
    }
    return false;
  }

  public static SetCachedMaxLength(
    cacheType: CacheType,
    maxLength: number
  ): void {
    LocalStorageFacade.setInt(
      UserService.USER_CACHED_MAX_LENGTH + cacheType,
      maxLength
    );
  }
  public static ClearCachedMaxLength(cacheType: CacheType): void {
    LocalStorageFacade.setInt(
      UserService.USER_CACHED_MAX_LENGTH + cacheType,
      0
    );
    ServiceProvider.getInstance(BookmarkService).init();
    ServiceProvider.getInstance(HistoryService).init();
  }
}
