export class ServiceProvider {
  private static instances: Map<new () => IService, IService> = new Map();

  public static getInstance<T extends IService>(constructor: new () => T): T {
    if (!ServiceProvider.instances.has(constructor)) {
      ServiceProvider.instances.set(constructor, new constructor());
    }
    return ServiceProvider.instances.get(constructor) as T;
  }

  public static setInstance<T extends IService>(
    constructor: new () => T,
    instance: T
  ): void {
    ServiceProvider.instances.set(constructor, instance);
  }
}

export interface IService {
  // IServiceのメソッドやプロパティの定義
}
