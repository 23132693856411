import HistoryModel from "@/models/HistoryModel";
import { QueryConstraint, orderBy, where } from "firebase/firestore";
import { BaseModelList } from "./BaseModelList";

export class HistoryService extends BaseModelList<HistoryModel> {
  constructor() {
    super(HistoryModel);
  }

  public async getUpdatedHistory(word: string): Promise<HistoryModel> {
    let userHistory = new HistoryModel().loadWord(word);
    const beforeHistory = await userHistory.getDoc();
    if (beforeHistory?.exists()) {
      userHistory = this.generateModel(beforeHistory);
      userHistory.updateTimeStamp();
    }
    userHistory.hidden = false;
    userHistory.update();
    this.add(userHistory);
    return userHistory;
  }

  protected getQuery(): QueryConstraint[] {
    return [orderBy("updatedAt", "desc"), where("hidden", "==", false)];
  }
}
