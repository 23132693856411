<template>
  <li class="ly_record">
    <label :for="`checkbox-${word}`" class="ly_checkbox-group" v-if="rootStore.sessionState.isEditMode">
      <input type="checkbox" class="record-checkbox" :id="`checkbox-${word}`" v-model="isChecked" />
      <span class="checkmark"></span>
      <span class="bl_text" :style="{ fontFamily: jpFont }">{{ word }}</span>
    </label>
    <router-link :to="{ name: 'word-detail', params: { word: word, lang: userLocale } }" class="record-content" v-else>
      <span class="bl_text" :style="{ fontFamily: jpFont }">{{ word }}</span>
      <!-- {{ updateAt }} -->
      <span class="arrow-right"></span>
    </router-link>
  </li>
</template>

<script setup lang="ts">
import { defineProps, onMounted, onUnmounted, ref } from "vue";
import { rootStore } from "@/services/RootStore";
import { useFontSelector } from "@/services/LocalizeService";
import eventService, { SessionAction } from "@/services/EventService";
import SessionService, { MenuType } from "@/services/SessionService";
import HistoryModel from "@/models/HistoryModel";
import UserService from "@/services/UserService";

const jpFont = useFontSelector().getFont(true);
const isChecked = ref(false);
const userLocale = UserService.getLanguageSetting();

// コンポーネントのpropsを定義します
// Remove the unused assignment of 'props' variable
const props = defineProps({
  word: String,
  history: HistoryModel
});

onMounted(() => {
  eventService.on(SessionAction.SelectAll, onSelectAll);
  eventService.on(SessionAction.ChangeEditMode, onModeChanged);
  eventService.on(SessionAction.Delete, onDelete);
  eventService.addRef(SessionAction.ItemChecked, isChecked);
});

onUnmounted(() => {
  eventService.off(SessionAction.SelectAll, onSelectAll);
  eventService.off(SessionAction.ChangeEditMode, onModeChanged);
  eventService.off(SessionAction.Delete, onDelete);
  eventService.removeRef(SessionAction.ItemChecked, isChecked);
});

function onModeChanged() {
  isChecked.value = false;
}

async function onDelete() {
  if (isChecked.value) {
    if (SessionService.GetNowMenuType() == MenuType.history) {
      const leftHistoriesCount = await props.history?.removeHistory();

      if (leftHistoriesCount == 0) {
        rootStore.ChangeEditMode(false);
      }
    } else if (SessionService.GetNowMenuType() == MenuType.bookmark) {
      const leftBookmarkCount = await props.history?.removeBookmark();
      if (leftBookmarkCount == 0) {
        rootStore.ChangeEditMode(false);
      }
    }
  }
}
function onSelectAll() {
  isChecked.value = true;
}

</script>

<style scoped>
.ly_record {
  display: flex;
  background-color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #EBF5EC;
  cursor: pointer;
  position: relative;
  align-content: left center;

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .bl_text {
    font-size: 1.125rem;
    color: #000;
    margin: 16px;
    flex-grow: 1;
  }

  .record-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &:hover>.bl_text {
      color: red;
    }


  }





  .record-checkbox {
    display: none;

    &:checked+.checkmark {
      background-image: url('~@/assets/images/parts/check_on.png');
    }
  }

  .ly_checkbox-group {
    width: 100%;
    height: 64px;

    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover>.bl_text {
      color: red;
    }
  }

  .checkmark {
    background-image: url('~@/assets/images/parts/check_off.png');
    width: 24px;
    height: 24px;
    margin: 20px 0px 20px 20px;
    background-size: cover;
  }

  .arrow-right {
    margin-right: 16px;
    /* top: 50%; */
    width: 16px;
    height: 16px;
    /* margin: 20px; */
    flex-grow: 0;
    background-image: url('~@/assets/images/parts/icon_arrow.png');
    background-size: cover;
  }
}
</style>@/services/RootStore@/services/LocalizeService@/utils/ServiceProvider
