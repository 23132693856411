import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithCustomToken,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  Auth,
  User,
  linkWithPopup,
  signInWithPopup,
} from "firebase/auth";
import "firebase/auth";

import ServerService from "./ServerService";
import FireStoreService from "./FireStoreService";
import UserService from "./UserService";
import { Ref, ref } from "vue";

export enum EnumAuthState {
  None,
  initializing,
  Authenticating,
  Authenticated,
  NotAuthenticated,
}
export enum EnumAuthMethod {
  None,
  Google,
  Custom,
}
export default class FirebaseService {
  public readonly fireStore: FireStoreService;
  public readonly auth: Auth;
  public readonly app: FirebaseApp;
  public nowAuthState: Ref<EnumAuthState> = ref(EnumAuthState.None);
  public authMethod: Ref<EnumAuthMethod> = ref(EnumAuthMethod.None);

  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyBQX52yt5A2Oa_LRaJFMmpyYXEHKjEfXh0",
      authDomain: "root-dictionary.firebaseapp.com",
      projectId: "root-dictionary",
      storageBucket: "root-dictionary.appspot.com",
      messagingSenderId: "999036336440",
      appId: "1:999036336440:web:108857abf996852a2669cd",
      measurementId: "G-KMKJGEGNRH",
    };

    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
    console.log("FirebaseService init");
    this.nowAuthState.value = EnumAuthState.initializing;
    onAuthStateChanged(this.auth, async (user) => {
      if (user) {
        // const idToken = await user.getIdToken();
        console.log("onAuthStateChanged トークン:");

        this.nowAuthState.value = EnumAuthState.Authenticated;
        this.authMethod.value = EnumAuthMethod.Custom;
        user.providerData.forEach((profile) => {
          console.log("Sign-in provider: " + profile.providerId);
          if (profile.providerId == "google.com") {
            this.authMethod.value = EnumAuthMethod.Google;
          }
        });
        UserService.fetchUserInfo();
      } else {
        console.log("no  user:");
        this.nowAuthState.value = EnumAuthState.NotAuthenticated;
      }
    });
    this.fireStore = new FireStoreService(this.app);
  }

  public isSignedIn(): boolean {
    return this.auth.currentUser !== null;
  }

  async getIdToken(): Promise<string> {
    try {
      let user = this.auth.currentUser;
      if (user) {
        try {
          const token = await user.getIdToken();
          return token;
        } catch (error) {
          console.error("トークンの取得に失敗しました:", error);
        }
      }
      user = await this.signIn();
      if (user == null) {
        console.error("user is null");
        return "error";
      }
      const tokenResult = await user.getIdTokenResult();

      return tokenResult.token;
    } catch (error) {
      console.error("Error signing in anonymously:", error);
      return "";
    }
  }

  public async signIn(): Promise<User> {
    this.nowAuthState.value = EnumAuthState.Authenticating;
    if (UserService.IsAuthByGoogle()) {
      const user = await this.loginByGoogle();

      //いらないかも
      UserService.LoginWithGoogle(user.uid);
      return user;
    }
    const customToken = await ServerService.getCustomToken();
    if (customToken == null) {
      throw new Error(
        "customToken is null: data" +
          UserService.getUserId() +
          "_" +
          UserService.getUserPass()
      );
    }

    const userCredential = await signInWithCustomToken(this.auth, customToken);
    return userCredential.user;
  }

  public async loginByGoogle(): Promise<User> {
    const provider = new GoogleAuthProvider();
    const userCredential = await signInWithPopup(this.auth, provider);
    return userCredential.user;
  }

  public async signInByGoogle(): Promise<User> {
    const user = this.auth.currentUser;
    if (user == null) {
      throw new Error("user is null");
    }
    const provider = new GoogleAuthProvider();
    const beforeUserId = user.uid;
    let afterUserId = "";
    try {
      const result = await linkWithPopup(user, provider);
      // const googleCredential = GoogleAuthProvider.credentialFromResult(result);
      console.log(
        "認証成功:user" + user.uid + "result.user.uid" + result.user.uid
      );
      afterUserId = result.user.uid;
    } catch (error: any) {
      // ここでエラーに基づいた条件分岐処理を行うことが可能
      if (error.code === "auth/credential-already-in-use") {
        // 全ての履歴を取得
        // const docs = await ServiceProvider.getInstance(
        //   HistoryService
        // ).getAllDocs();
        const existingUser = await this.loginByGoogle();
        // await ServiceProvider.getInstance(HistoryService).setAllDocs(
        //   existingUser.uid,
        //   docs
        // );
        console.log(
          "このクレデンシャルは既にこのユーザに紐づいているので、アカウントを結合しました。 "
        );
        UserService.LoginWithGoogle(existingUser.uid);
        afterUserId = existingUser.uid;
      }
    }
    const isSuccess = await UserService.SignInWithGoogle(
      beforeUserId,
      afterUserId
    );
    console.log("isSuccess" + isSuccess);
    return user;
  }
  public async signOut(): Promise<void> {
    return await signOut(this.auth);
  }
}

export const firebaseService = new FirebaseService();
