import { DocumentData, Timestamp } from "firebase/firestore";
import UserService from "@/services/UserService";
import { firebaseService } from "@/services/FireAuthService";
import { BookmarkService } from "@/services/BookmarkService";
import { BaseModel } from "./BaseModel";
import { ServiceProvider } from "@/utils/ServiceProvider";
import { HistoryService } from "@/services/HistoryService";

export default class HistoryModel extends BaseModel<HistoryModel> {
  protected getDocPath(): string {
    const userId = UserService.getUserId();
    return `users/${userId}/history`;
  }
  public getDocKey(): string {
    return this.word;
  }
  constructor() {
    super();
  }

  word: string = "error";
  createdAt!: Timestamp;
  updatedAt: Timestamp | null = null;
  bookmarkedAt: Timestamp | null = null;
  records!: Timestamp[];
  bookmark: boolean = false;
  hidden: boolean = false;

  toDocData(): DocumentData {
    return {
      word: this.word,
      createdAt: this.createdAt ? this.createdAt : null,
      updatedAt: this.updatedAt ? this.updatedAt : null,
      records: this.records ? this.records : null,
      bookmark: this.bookmark ? this.bookmark : false,
      bookmarkedAt: this.bookmarkedAt ? this.bookmarkedAt : null,
      hidden: this.hidden ? this.hidden : false,
    };
  }

  public async changeBookMark() {
    this.bookmark = !this.bookmark;

    if (this.bookmark) {
      this.bookmarkedAt = Timestamp.now();
    } else {
      this.bookmarkedAt = null;
    }
    super.update();

    if (this.bookmark) {
      ServiceProvider.getInstance(BookmarkService).add(this);
    } else {
      ServiceProvider.getInstance(BookmarkService).remove(this);
    }
  }

  public updateTimeStamp(): void {
    const now = Timestamp.now();

    const lastRecord =
      this.records.length > 0 ? this.records[this.records.length - 1] : null;
    const timeDiff = now.toMillis() - (lastRecord?.toMillis() ?? 0);
    if (timeDiff >= 600000) {
      this.records.push(now);
    }

    this.updatedAt = now;
  }

  public getDate(): string {
    return this.updatedAt!.toDate().toISOString().split("T")[0];
  }

  public static getHistoriesPerDate(
    histories: HistoryModel[]
  ): Record<string, HistoryModel[]> {
    const groups: Record<string, HistoryModel[]> = {};

    histories.sort(
      (a: HistoryModel, b: HistoryModel) =>
        b.updatedAt!.toDate().getTime() - a.updatedAt!.toDate().getTime()
    );
    histories.forEach((history) => {
      const dateStr = history.getDate();
      if (!groups[dateStr]) {
        groups[dateStr] = [];
      }
      groups[dateStr].push(history);
    });

    const sortedDates = Object.keys(groups).sort((a, b) => b.localeCompare(a));
    const sortedGroups: Record<string, HistoryModel[]> = {};

    sortedDates.forEach((date) => {
      sortedGroups[date] = groups[date];
    });
    return sortedGroups;
  }
  public async removeHistory(): Promise<number> {
    this.hidden = true;
    await super.update();
    return ServiceProvider.getInstance(HistoryService).remove(this);
  }
  public async removeBookmark(): Promise<number> {
    this.bookmark = false;
    this.bookmarkedAt = null;
    await super.update();
    return ServiceProvider.getInstance(BookmarkService).remove(this);
  }
  public loadWord(word: string): HistoryModel {
    this.word = word || "";
    this.bookmark = false;
    this.createdAt = Timestamp.now();
    this.updatedAt = Timestamp.now();
    this.records = [];
    this.bookmark = false;
    this.bookmarkedAt = null;
    this.hidden = false;
    return this;
  }
}
