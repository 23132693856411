import { computed } from "vue";
import { useI18n } from "vue-i18n";

export function useImagePath() {
  const { locale } = useI18n();

  function getImagePath(fileName: string): string {
    return require(`@/assets/images/${locale.value}/${fileName}`);
  }

  return { getImagePath };
}

export function useFontSelector() {
  const { locale } = useI18n();

  function getFont(isJp: boolean): string {
    switch (locale.value) {
      case "ja":
        if (isJp) {
          return "Hiragino Kaku Gothic Pro,Noto Sans CJK JP, sans-serif";
        }
        return "Helvetica,Roboto, sans-serif";
      case "en":
      default:
        return "Avenir,Roboto,sans-serif";
    }
  }

  return { getFont };
}

export function useDateFormat() {
  const { locale } = useI18n();

  function formatDate(dateStr: string): string {
    const date = new Date(dateStr);

    switch (locale.value) {
      case "ja":
        return date.toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        return "Helvetica,Roboto, sans-serif";
      case "en":
      default:
        return date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
    }
  }

  return { formatDate };
}
