import HistoryModel from "@/models/HistoryModel";
import { QueryConstraint, orderBy, where } from "firebase/firestore";
import { BaseModelList } from "./BaseModelList";

export class BookmarkService extends BaseModelList<HistoryModel> {
  constructor() {
    super(HistoryModel);
  }

  protected getQuery(): QueryConstraint[] {
    return [orderBy("bookmarkedAt", "desc"), where("bookmark", "==", true)];
  }
}
