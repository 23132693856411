import { createI18n } from "vue-i18n";
import Papa from "papaparse";
import axios from "axios";
import UserService from "./services/UserService";

// CSVの行データの型を定義
interface CsvRow {
  category: string;
  key: string;
  [locale: string]: string;
}

export async function initI18n(): Promise<any> {
  const i18n = createI18n({
    locale: UserService.getLanguageSetting() || "ja",
    legacy: false,
    fallbackLocale: "ja",
    messages: {},
  });
  const baseUrl = window.location.origin; // 現在のオリジンを取得
  const filePath = `${baseUrl}/localize.csv`; // 絶対URLを生成
  const messages = await loadMessagesFromCSV(filePath);
  i18n.global.mergeLocaleMessage("en", messages.en);
  i18n.global.mergeLocaleMessage("ja", messages.ja);

  return i18n;
}

// i18nインスタンスを作成（初期状態）

// CSVファイルを読み込んで、言語メッセージオブジェクトを生成する関数
async function loadMessagesFromCSV(filePath: string) {
  const response = await axios.get(filePath);
  const csvData = response.data;
  const parsedData = Papa.parse<CsvRow>(csvData, { header: true });

  const messages: {
    [locale: string]: { [category: string]: { [key: string]: string } };
  } = {};

  parsedData.data.forEach((row: CsvRow) => {
    const { category, key, ...translations } = row;
    Object.entries(translations).forEach(([locale, translation]) => {
      if (!messages[locale]) {
        messages[locale] = {};
      }
      if (!messages[locale][category]) {
        messages[locale][category] = {};
      }
      messages[locale][category][key] = translation;
    });
  });

  return messages;
}

// CSVファイルからメッセージを非同期で読み込み、i18nインスタンスにセットする
