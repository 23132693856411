import eventService, { SessionAction } from "./EventService";

export enum CacheType {
  History,
  // ChangeEditMode,
  // Delete,
}

export enum MenuType {
  setting = "setting",
  dictionary = "dictionary",
  history = "history",
  bookmark = "bookmark",
  word = "word",
}
export default class SessionService {
  public static SetNowMenuType(menuType: MenuType): void {
    sessionStorage.setItem("NowMenuType", menuType.toString());
    eventService.emit(SessionAction.ChangeMenuType, menuType);
  }

  public static GetNowMenuType(): MenuType {
    const menuTypeStr: string | null = sessionStorage.getItem("NowMenuType");
    const nowMenu: MenuType = MenuType[menuTypeStr as keyof typeof MenuType];

    if (nowMenu != undefined) {
      return nowMenu;
    }
    return MenuType.dictionary;
  }
}
