<template>
  <PopupComponent v-if="showWellcomPopup" :message="deletePopupComment" cancelTxt="しないで使う" confirmTxt="ログインする"
    @confirmed="startWithLogin" @cancelled="startWithoutLogin" />

  <div class="root" v-if="isLoading">
    <section class="gl_ly_main_blank">
      <img class="gl_bl_icon" src="./assets/images/parts/loading.gif" alt="Loading..." loading="lazy" width="120px"
        height="120px">
      <span class="gl_bl_text">Loading...</span>
    </section>
  </div>
  <div class="root" v-else>
    <div class="main" id="ly_main">
      <router-view />
    </div>
    <div class="footer">
      <PopupComponent v-if="showDeletePopup" :message="deletePopupComment" cancelTxt="キャンセル" confirmTxt="削除"
        @confirmed="handleConfirm" @cancelled="handleCancel" />
      <section class="ly_action_edit" v-if="rootStore.sessionState.isEditMode">
        <button class="bl_select-all" @click="buttonSelectAll" v-html="$t('lt_menu.select-all')"></button>
        <button class="bl_delete" @click="buttonDelete"></button>
      </section>

      <nav class="ly_menu" v-else>
        <router-link to="/" class="bl_menu-item"
          :class="{ 'active': activeItem === 'dictionary' || activeItem === 'word' }" @click="clickMenu('dictionary')"
          id="dictionary">
          {{ $t('lt_menu.dictionary') }}
        </router-link>
        <router-link to="/bookmark" class="bl_menu-item" :class="{ 'active': activeItem === 'bookmark' }"
          @click="clickMenu('bookmark')" id="bookmark">
          {{ $t('lt_menu.bookmark') }}
        </router-link>
        <router-link to="/history" class="bl_menu-item" :class="{ 'active': activeItem === 'history' }"
          @click="clickMenu('history')" id="history">
          {{ $t('lt_menu.history') }}
        </router-link>
      </nav>
    </div>
  </div>

</template>

<script lang="ts" setup>
import PopupComponent from './components/YesNoPopup.vue';

import { onMounted, ref } from 'vue';
import { rootStore } from '@/services/RootStore';
import eventService, { SessionAction } from './services/EventService';
import UserService from './services/UserService';
import { EnumAuthState, firebaseService } from './services/FireAuthService';
import SessionService from './services/SessionService';
import { onUnmounted } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();


const showDeletePopup = ref(false);
const deletePopupComment = computed(() => {
  return 'RootDictionaryへようこそ！\nもし他の端末などで利用された事があれば、\nログインするとデータの同期ができます♪';
});

const authState = firebaseService.nowAuthState;
console.log("authState", authState);
const showWellcomPopup = computed(() => {

  if (authState.value == EnumAuthState.NotAuthenticated) {
    if (UserService.isRegistered() == false) {
      if (route.path.startsWith('/word/')) {
        return false;
      }
      return true
    }
  }
  return false;
});
const isLoading = computed(() => {

  if (authState.value == EnumAuthState.NotAuthenticated) {
    if (UserService.isRegistered()) {
      firebaseService.signIn()
    }
  }
  if (route.path.startsWith('/word/')) {
    return false;
  }
  return authState.value != EnumAuthState.Authenticated;
});

async function startWithLogin() {
  console.log('startWithLogin');
  await UserService.Register();
  await firebaseService.signIn();
  await firebaseService.signInByGoogle();
}
async function startWithoutLogin() {
  console.log('startWithoutLogin');
  await UserService.Register();
  await firebaseService.signIn()
}
onMounted(async () => {
  const newLocal = SessionService.GetNowMenuType().toString();
  activeItem.value = newLocal;
  eventService.on(SessionAction.ChangeMenuType, onChangeMenu);
});

onUnmounted(() => {
  eventService.off(SessionAction.ChangeMenuType, onChangeMenu);
});

function onChangeMenu(menuType: string) {
  activeItem.value = menuType;
}

const newLocal = SessionService.GetNowMenuType().toString();
const activeItem = ref(newLocal);

function clickMenu(menu: string) {
  activeItem.value = menu;
}

function buttonSelectAll() {
  eventService.emit(SessionAction.SelectAll, "top");
}

function buttonDelete() {
  console.log('buttonDelete');
  showDeletePopup.value = true;
}
function handleConfirm() {
  eventService.emit(SessionAction.Delete, "top");
  showDeletePopup.value = false;
}
function handleCancel() {
  showDeletePopup.value = false;
}

</script>

<style scoped lang="scss">
.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  height: calc(100% - 96px);
  overflow: auto;
}

.footer {
  flex-grow: 0;
  height: 96px;
  background-color: #FAFAFA;
  border: 1px solid #E5EDE6
}



.ly_action_edit {
  flex-grow: 1;
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 25px;

  .bl_select-all {
    display: inline-block;
    color: #409148;
    font-size: 1.125rem;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .bl_delete {
    &::before {
      content: '';
      display: block;
      width: 24px;
      height: 24px;
      background-size: cover;
      margin-bottom: 5px;
      background-image: url('~@/assets/images/parts/button_delete.png');
      cursor: pointer;

    }

    &:hover::before {
      opacity: 0.5;
      transition: 0.3s;
    }
  }
}

.ly_menu {
  flex-grow: 0;
  display: flex;
  justify-content: space-around;

  .bl_menu-item {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    height: 96px;
    padding: 10px 0 24px;
    border-top: 1px solid transparent;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #000;
    font-size: 0.75rem;
    padding: 0.5rem;
    transition: all 0.3s;

    &::before {
      content: '';
      display: block;
      width: 32px;
      height: 32px;
      background-size: cover;
      margin-bottom: 5px;
    }

    &#dictionary {
      &::before {
        background-image: url('~@/assets/images/parts/icon_dictionary_off.png');
      }

      &.active::before {
        background-image: url('~@/assets/images/parts/icon_dictionary_on.png');
      }
    }

    &#bookmark {
      &::before {
        background-image: url('~@/assets/images/parts/icon_bookmark_off.png');
      }

      &.active::before {
        background-image: url('~@/assets/images/parts/icon_bookmark_on.png');
      }
    }

    &#history {
      &::before {
        background-image: url('~@/assets/images/parts/icon_history_off.png');
      }

      &.active::before {
        background-image: url('~@/assets/images/parts/icon_history_on.png');
      }
    }

    &.active {
      color: #409148;
    }

  }




}
</style>