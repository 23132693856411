<!-- eslint-disable no-unused-vars -->
<script lang="ts" setup>

import { onMounted } from "vue";


import { useImagePath } from '@/services/LocalizeService';
import SessionService, { MenuType } from "@/services/SessionService";
import WordSearchBox from "@/components/WordSearchBox.vue";

const { getImagePath } = useImagePath();



onMounted(() => {
  SessionService.SetNowMenuType(MenuType.dictionary);
});


</script>

<template>
  <div class="p-4">

    <router-link to="/setting" class="flex flex-col right-4 space-y-2 text-gray-600 hover:text-green-600">
      <button id="settings-button" class=" hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-opacity-50">
        <img src="@/assets/button_setting.png" alt="Settings" class="w-6 h-6" />
      </button>
    </router-link>

    <header class="text-center mt-8 mb-4">
      <img :src="getImagePath('home_logo.png')" alt="Root Dictionary Logo" class="mx-auto" />
    </header>
    <WordSearchBox></WordSearchBox>
  </div>
</template>

<style scoped lang="scss">
#settings-button {
  @apply fixed top-4 right-4;

  &:hover {
    background-color: #ddd;
  }
}
</style>@/services/LocalizeService