export default class LocalStorageFacade {
  static setItem(key: string, value: string): void {
    const encodedValue = btoa(value); // Base64エンコード
    localStorage.setItem(key, encodedValue);
  }

  static getItem(key: string): string | null {
    const encodedValue = localStorage.getItem(key);
    return encodedValue ? atob(encodedValue) : null; // Base64デコード
  }

  static setInt(key: string, value: number): void {
    LocalStorageFacade.setItem(key, value.toString());
  }

  static getInt(key: string, def: number): number {
    const value = LocalStorageFacade.getItem(key);
    return value ? parseInt(value) : def;
  }

  static removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}
