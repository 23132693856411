import {
  DocumentData,
  DocumentSnapshot,
  QueryConstraint,
  QuerySnapshot,
  startAfter,
} from "firebase/firestore";
import { Ref, computed, ref, watch } from "vue";
import UserService from "./UserService";
import { firebaseService } from "./FireAuthService";
import { plainToClass } from "class-transformer";
import { BaseModel } from "@/models/BaseModel";
import { IService } from "@/utils/ServiceProvider";

export abstract class BaseModelList<T extends BaseModel<T>>
  implements IService
{
  protected lastSnap: DocumentSnapshot | null = null;
  public models: Ref<T[]> = ref([]);
  public hasMore: Ref<boolean> = ref(true);
  protected abstract getQuery(): QueryConstraint[];

  public init() {
    this.lastSnap = null;
    this.models.value = [];
    this.hasMore.value = true;
    this.loadList();
  }

  protected constructor(protected modelConstructor: new () => T) {
    this.loadList();
  }

  public remove(model: T): number {
    const index = this.models.value.findIndex(
      (item) => item.getDocKey() === model.getDocKey()
    );

    if (index > -1) {
      this.models.value.splice(index, 1);
    }
    return this.models.value.length;
  }
  public add(model: T) {
    this.remove(model);
    this.models.value.unshift(model);
  }

  public async loadList(): Promise<boolean> {
    const limitAmount = 10;
    const result = await this.getModels(
      this.lastSnap,
      limitAmount,
      this.models.value.length
    );
    let hasMore = false;
    if (result && result?.length > 0) {
      this.lastSnap = result[result.length - 1].docSnap;

      result.map((history: T) => {
        this.models.value.push(history);
      });

      if (result?.length >= limitAmount) {
        hasMore = true;
      }
    }
    this.hasMore.value = hasMore;
    return hasMore;
  }
  private async getModels(
    lastHistory: DocumentSnapshot | null,
    limitAmount: number,
    nowModelsLength: number
  ): Promise<T[] | null> {
    const userId: string = UserService.getUserId();
    const docs = await firebaseService.fireStore.getDocumentSnapshots(
      `users/${userId}/history`,
      limitAmount,
      nowModelsLength,
      ...this.getQuery(),
      ...(lastHistory ? [startAfter(lastHistory)] : [])
    );

    const historyModels: T[] = [];
    docs.forEach((doc) => {
      const history = this.generateModel(doc);
      historyModels.push(history);
    });

    return historyModels;
  }

  // public async setAllDocs(
  //   userId: string,
  //   docs: QuerySnapshot<DocumentData, DocumentData>
  // ): Promise<void> {
  //   await firebaseService.fireStore.setAllDocumentSnapshots(
  //     `users/${userId}/history`,
  //     docs
  //   );
  // }
  // public async getAllDocs(): Promise<
  //   QuerySnapshot<DocumentData, DocumentData>
  // > {
  //   const userId: string = UserService.getUserId();
  //   const docs = await firebaseService.fireStore.getAllDocumentSnapshots(
  //     `users/${userId}/history`
  //   );

  //   return docs;
  // }

  protected generateModel(doc: DocumentSnapshot): T {
    const item = doc.data();
    const model = plainToClass(this.modelConstructor, item);
    model.docSnap = doc;
    return model;
  }
}

export function useScrollLoad(
  loadMoreRef: any,
  loading: any,
  showLoadMore: any,
  loadList: () => Promise<boolean>
) {
  const handleScroll = async () => {
    if (loadMoreRef.value && !loading.value && showLoadMore.value) {
      const { bottom } = loadMoreRef.value.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      if (bottom <= windowHeight) {
        loading.value = true;
        showLoadMore.value = await loadList();
        loading.value = false;
      }
    }
  };

  const onUpdateElement = (newVal: any, oldVal: any) => {
    const historyList = document.getElementById("ly_main");
    if (newVal && !oldVal) {
      historyList!.addEventListener("scroll", handleScroll);
    } else if (!newVal && oldVal) {
      historyList!.removeEventListener("scroll", handleScroll);
    }
  };

  watch(loadMoreRef, onUpdateElement);

  return {
    handleScroll,
    onUpdateElement,
  };
}
