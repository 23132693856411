import { createRouter, createWebHistory } from "vue-router";
import DictionaryView from "../views/DictionaryView.vue";
import { reactive } from "vue";
import UserService from "@/services/UserService";

const routes = [
  {
    path: "/",
    name: "dictionary",
    component: DictionaryView,
  },
  {
    path: "/bookmark",
    name: "bookmark",
    component: () => import("../views/BookmarkView.vue"),
  },
  {
    path: "/history",
    name: "history",
    component: () => import("../views/HistoryView.vue"),
  },
  {
    path: "/word/:word/:lang",
    name: "word-detail",
    component: () => import("../views/WordDetailView.vue"),
    props: (route: { params: any }) => ({
      ...route.params,
      lang: route.params.lang,
    }),
  },
  {
    path: "/word/:word/",
    name: "word-detail-default",
    component: () => import("../views/WordDetailView.vue"),
    props: true,
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/SettingView.vue"),
    props: true, // ルートパラメータをpropsとしてコンポーネントに渡す
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const routeHistory: { from: string; to: string }[] = reactive([]);
router.beforeEach((to, from, next) => {
  console.log(to);
  if (
    window.location.protocol === "http:" &&
    window.location.hostname !== "localhost"
  ) {
    const secureUrl = `https://${location.host}${to.fullPath}`;
    window.location.replace(secureUrl);
    return;
  }
  if (to.name === "word-detail-default") {
    const userLocale = UserService.getLanguageSetting();
    next({
      name: "word-detail",
      params: { word: to.params.word, lang: userLocale },
    });
    return;
  }
  // 'to' と 'from' はそれぞれ移動先と移動元のルートオブジェクト
  const lowerCasePath = to.path.toLowerCase();
  if (to.path !== lowerCasePath) {
    next(lowerCasePath); // 小文字のパスにリダイレクト
    return;
  }

  if (
    routeHistory.length > 1 &&
    routeHistory[routeHistory.length - 1].from === to.fullPath
  ) {
    // Do nothing
  } else {
    routeHistory.push({ from: from.fullPath, to: to.fullPath });
  }
  next();
});

router.afterEach((to, from) => {
  // 戻る操作が発生したとき、スタックから最後の要素を削除
  if (
    routeHistory.length > 1 &&
    routeHistory[routeHistory.length - 1].from === to.fullPath
  ) {
    routeHistory.pop(); // 最後の要素を削除
  }
});

// 必要に応じて履歴データを利用
export function getRouteHistory() {
  return routeHistory;
}

export default router;
