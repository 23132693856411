import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./index.css"; //ここを追記
import { initI18n } from "./i18n";
import "./assets/styles/sanitize.css";
import "./assets/styles/global.scss";
import { createHead } from "@vueuse/head";

const i18n = await initI18n();
createApp(App)
  .use(i18n)
  .use(router)
  .use(createHead() as any)
  .mount("#app");

export default i18n;
