import UserService from "@/services/UserService";
import { Expose, plainToInstance } from "class-transformer";
import i18n from "@/main";
import { formToJSON } from "axios";

class AffixEntry {
  affix_type?: string;

  affix_word?: string;

  affix_meaning: string = "";
}

type WordEntry = {
  definition: string;
  part_of_speech: string;
  synonyms: string[];
  antonyms: string[];
  examples: string[];
};

type DerivedForms = {
  [key: string]: string[];
};

type LocalizationEntry = {
  definition: { [key: string]: string };
  examples: { [key: string]: string };
  affix_meaning: { [key: string]: string };
  origin?: string;
};

type PronunciationEntry = {
  all?: string;
  noun?: string;
  verb?: string;
  unstressed?: string;
};

type RegionPronunciation = {
  [region: string]: PronunciationEntry;
};

export default class WordInfo {
  @Expose({ name: "word_entries" })
  wordEntries: WordEntry[] = [];

  @Expose({ name: "pronunciation_entries" })
  pronunciationEntries: RegionPronunciation[] = [];

  @Expose({ name: "derived_forms" })
  derivedForms: DerivedForms = {};

  origin: string = "";
  word: string = "";
  root_word: string = "";
  root_meaning: string = "";

  same_root_words: string[] = [];
  spells: string[] = [];

  localization: { [language: string]: LocalizationEntry } = {};

  @Expose({ name: "affix_entries" })
  affixEntries: AffixEntry[] = [];

  inflictions: string[] = [];
  baseForm: string[] = [];
  // メソッドの追加
  static buildFromJson(json: any): WordInfo {
    const inst = plainToInstance(WordInfo, json);
    if (json.word) inst.word = json.word;
    if (json.wordEntries) inst.wordEntries = json.wordEntries;
    if (json.pronunciationEntries)
      inst.pronunciationEntries = json.pronunciationEntries;
    if (json.derivedForms) inst.derivedForms = json.derivedForms;
    if (json.origin) inst.origin = json.origin;
    if (json.root_word) inst.root_word = json.root_word;
    if (json.root_meaning) inst.root_meaning = json.root_meaning;
    if (json.same_root_words) inst.same_root_words = json.same_root_words;
    if (json.localization) inst.localization = json.localization;
    if (json.affixEntries)
      inst.affixEntries = json.affixEntries.map((entry: AffixEntry) =>
        plainToInstance(AffixEntry, entry)
      );
    if (json.spells) inst.spells = json.spells;
    if (json.inflictions) inst.inflictions = json.inflictions;
    if (json.baseForm) inst.baseForm = json.baseForm;

    return inst;
  }
  public getMeanings(): string {
    // ## 語形変化の定義
    // BF: Base Form (基本形)
    // 3PS: Third Person Singular (三人称単数)
    // ING: Present Participle (現在分詞)
    // PT: Past Tense (過去形)
    // PP: Past Participle (過去分詞)
    // PL: Plural (複数形)
    // COMP: Comparative (比較級)
    // SUPERL: Superlative (最上級)
    // NA: Not Applicable (該当なし)
    let inflictionInfo = "";
    const filteredInflections = this.inflictions.filter(
      (inflection) => inflection !== "BF" && inflection !== "NA"
    );

    if (filteredInflections.length > 0) {
      const localizedFilteredInflections = filteredInflections.map(
        (inflection) => {
          return i18n.global.t(`lt_common.${inflection}`, inflection);
        }
      );
      inflictionInfo =
        "【" +
        this.baseForm.join(", ") +
        "→" +
        localizedFilteredInflections.join(", ") +
        "\n】";
      // return this.inflections.join(", ");
    }

    return (
      inflictionInfo +
      this.wordEntries
        .map((entry) => {
          // ロケールが 'ja' で、対応する日本語の定義がある場合、それを使用
          if (
            i18n.global.locale.value === "ja" &&
            this.localization["ja"]?.definition[entry.definition]
          ) {
            return this.localization["ja"].definition[entry.definition];
          }
          // そうでなければ、元の定義を使用
          return entry.definition;
        })
        .join(", ")
    );
  }

  public getMeaning(definition: string): string {
    if (
      i18n.global.locale.value === "ja" &&
      this.localization["ja"]?.definition[definition]
    ) {
      return this.localization["ja"].definition[definition];
    }
    return definition;
  }

  public getOrigin(): string {
    if (i18n.global.locale.value === "ja" && this.localization["ja"]?.origin) {
      return this.localization["ja"].origin;
    }

    return this.origin;
  }

  public getRootWithMeaning(): string {
    return this.root_word + "(" + this.root_meaning + ")";
  }

  public getAffixMeaning(affix_meaning: string): string {
    // ロケールが 'ja' で、対応する日本語の定義がある場合、それを使用
    if (
      i18n.global.locale.value === "ja" &&
      this.localization["ja"]?.affix_meaning?.[affix_meaning]
    ) {
      return this.localization["ja"].affix_meaning[affix_meaning];
    }
    // そうでなければ、元の定義を使用
    return affix_meaning;
  }
  public getTitle(): string {
    let result = "";
    if (this.spells == null || this.spells.length == 0) {
      return this.word;
    }
    const values = Array.from(this.spells);

    for (const words of values) {
      if (words.length > 0) {
        result += `${words}, `;
      }
    }
    return result.substring(0, result.length - 2);
  }

  public generatePronunciationString(): string {
    let result = "";

    this.pronunciationEntries.forEach((entry) => {
      const usPronunciation = entry["us"]?.all;
      const ukPronunciation = entry["uk"]?.all;
      const ausPronunciation = entry["aus"]?.all;
      const unstressed = entry["unstressed"]?.unstressed;

      if (usPronunciation) {
        result += usPronunciation;
      }

      if (unstressed && unstressed !== usPronunciation) {
        result += ` unstress ${unstressed}`;
      }

      if (ukPronunciation && ukPronunciation !== usPronunciation) {
        result += ` 【uk】 ${ukPronunciation}`;
      }

      if (ausPronunciation && ausPronunciation !== usPronunciation) {
        result += ` 【aus】 ${ausPronunciation}`;
      }
    });

    return result;
  }
  public formatDerivedForms(): string {
    let result = "";
    const values = Array.from(new Set(Object.values(this.derivedForms).flat()));

    for (const words of values) {
      if (words.length > 0) {
        result += `${words}, `;
      }
    }
    return result.substring(0, result.length - 2);
    // for (const [form, words] of Object.entries(this.derivedForms)) {
    //   if (words.length > 0) {
    //     result += `【${form}】: ${words.join(", ")}<br>`;
    //     // result += `${words.join(", ")}\n`;
    //   }
    // }

    // return result.trim();
  }
}
