import UserService from "./UserService";
import WordInfo from "@/models/WordInfo";
import { firebaseService } from "./FireAuthService";
import LocalStorageFacade from "./LocalStorageFacade";

export default class ServerService {
  private static async postRequest(
    endpoint: string,
    bodyContent: Object,
    idToken: string = "",
    lang: string = ""
  ): Promise<Response | null> {
    try {
      const userId = UserService.getUserId();
      if (lang === "") {
        lang = UserService.getLanguageSetting();
      }

      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idToken,
          userId,
          lang,
          ...bodyContent,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response;
    } catch (error) {
      console.error(`Error during the POST request to ${endpoint}:`, error);
      return null;
    }
  }

  private static async handleResponse(response: Response | null): Promise<any> {
    if (response === null) {
      console.error("response === null");
      throw new Error("response === null");
    }
    if (response.ok) {
      const json = await response.json();
      if (json.error) {
        console.error("サーバーエラー", json.error);
        throw new Error(json.error);
      }
      return json;
    } else {
      console.error("サーバーエラー", response);
      throw new Error("HTTP error! status: " + response.status);
    }
  }

  public static async getWordData(
    word: string,
    lang: string
  ): Promise<WordInfo | null> {
    // const idToken = await firebaseService.getIdToken();
    const response = await this.postRequest(
      "/api/get_dictionary",
      {
        word,
        // idToken,
      },
      "",
      lang
    );
    const json = await this.handleResponse(response);
    return WordInfo.buildFromJson(json);
  }

  public static async sendUserSetting(
    languageSetting: string
  ): Promise<boolean> {
    const idToken = await firebaseService.getIdToken();
    const response = await this.postRequest("/api/save_user", {
      languageSetting,
      idToken,
    });
    await this.handleResponse(response);
    return true;
  }

  public static async signInUserWithGoogle(
    beforeUserId: string,
    afterUserId: string
  ): Promise<boolean> {
    const idToken = await firebaseService.getIdToken();
    const response = await this.postRequest("/api/sign_in_with_google", {
      idToken,
      userPass: UserService.getUserPass(),
      beforeUserId,
      afterUserId,
    });
    const json = await this.handleResponse(response);
    UserService.setLocalLanguageSetting(json.languageSetting);
    return true;
  }

  public static async registerUser(userId: string): Promise<string | null> {
    const languageSetting = UserService.getLanguageSetting();
    const browserId = UserService.getBrowserId();
    const response = await this.postRequest("/api/register_user", {
      languageSetting,
      userId,
      browserId,
    });
    const json = await this.handleResponse(response);
    return json.userPass;
  }

  public static async getCustomToken(): Promise<string> {
    if (UserService.IsAuthByGoogle()) {
      throw new Error("Google認証の場合は、getCustomTokenを呼び出せません");
    }
    const response = await this.postRequest("/api/get_custom_token", {
      userPass: UserService.getUserPass(),
    });
    const json = await this.handleResponse(response);
    return json.customToken;
  }
  public static async getUserInfo(
    userId: string,
    browserId: string
  ): Promise<{ lastLoginBrowserId: string; languageSetting: string }> {
    const idToken = await firebaseService.getIdToken();

    const response = await this.postRequest("/api/get_user_info", {
      userId,
      browserId,
      idToken,
    });
    const json = await this.handleResponse(response);
    return json;
  }
}
