import { Ref } from "vue";

export type EventCallback = (payload: any) => void;
export enum SessionAction {
  SelectAll,
  ChangeEditMode,
  Delete,
  ChangeMenuType,
  ItemChecked,
}
class EventService {
  private listeners: Map<SessionAction, EventCallback[]>;
  private refs: Map<SessionAction, Ref[]>;

  constructor() {
    this.listeners = new Map();
    this.refs = new Map();
  }

  public getRef(event: SessionAction): Ref[] {
    return this.refs.get(event) || [];
  }

  public addRef(event: SessionAction, ref: Ref) {
    this.refs.has(event) || this.refs.set(event, []);
    this.refs.get(event)?.push(ref);
  }
  public removeRef(event: SessionAction, ref: Ref) {
    const refs = this.refs.get(event);
    if (refs && refs.length) {
      this.refs.set(
        event,
        refs.filter((listener) => listener !== ref)
      );
    }
  }

  public on(event: SessionAction, callback: EventCallback): void {
    this.listeners.has(event) || this.listeners.set(event, []);
    this.listeners.get(event)?.push(callback);
  }

  public off(event: SessionAction, callback: EventCallback): void {
    const listeners = this.listeners.get(event);
    if (listeners && listeners.length) {
      this.listeners.set(
        event,
        listeners.filter((listener) => listener !== callback)
      );
    }
  }

  public emit(event: SessionAction, payload: any): void {
    const listeners = this.listeners.get(event);
    if (listeners && listeners.length) {
      listeners.forEach((listener) => listener(payload));
    }
  }
}
const eventService = new EventService();

export default eventService;
